import lottie from "lottie-web";

let blob = document.getElementById("blob");
let basespeed = 0.8;

// JSON animation
let animation = lottie.loadAnimation({
  container: blob,
  renderer: "svg",
  loop: true,
  autoplay: false, // Initially set autoplay to false
  speed: basespeed,
  path: "/blob-anim.json",
});

animation.addEventListener("DOMLoaded", function () {
  const blobSVG = document.querySelector("#blob svg g g g");
  blobSVG.id = "blobSvg";

  // Remove classes that hide the blob and move it off-screen

  setTimeout(function () {
    blob.classList.remove("opacity-0");
    blob.classList.add("opacity-100"); // Add classes to make it visible and move it into view
    animation.play(); // Start playing the animation
  }, 300);
});

// Detect mouse or touch screen
let isTouchDevice = "ontouchstart" in document.documentElement;
if (!isTouchDevice) {
  // Mouse distance => animation speed
  let midpoint = [100, 100];
  let maxDistance = 300;
  let maxSpeed = 12;
  let steepness = 1.3;
  document.addEventListener("mousemove", function (event) {
    let mouseX = event.clientX;
    let mouseY = event.clientY;
    let distance = Math.sqrt(
      (mouseX - midpoint[0]) ** 2 + (mouseY - midpoint[1]) ** 2,
    );
    let speed =
      basespeed +
      (maxSpeed - basespeed) * Math.exp((-steepness * distance) / maxDistance);

    if (animation) {
      animation.setSpeed(speed);
    }
  });
}

// Blob random rotation
function rotateBlob() {
  let random = Math.floor(Math.random() * 360);
  // Apply rotation using inline style
  blob.children[0].style.transform = `rotate(${random}deg)`;
}

setInterval(rotateBlob, 5000);

/*
//Blob parallax
const parallaxAmount = -0.4;
function handleScroll() {
  const translateY = window.scrollY * parallaxAmount;
  blob.style.transform = `translateY(${translateY}px) scale(3) rotate(90deg)`;
}
window.addEventListener("scroll", handleScroll);
*/

export { animation };
